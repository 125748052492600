import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import AffiliateThankYou from "../../../page-components/affiliates/thank-you";
import bg from "../../../images/svg/vector-07.svg";

const AffiliatePage = () => {
  return (
    <Layout isLanding useNewLPHeader className="non-fixed-width">
      <SEO title="SpotOn Affiliate" />
      <img
        src={bg}
        alt="background"
        className="hidden md:block absolute top-20 right-0 "
      />
      <AffiliateThankYou />
    </Layout>
  );
};

export default AffiliatePage;
